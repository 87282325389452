<template>
    <div v-if="x != null">
        <v-card flat class="shadow mx-auto mt-5" max-width="1100">
            <v-row class="pa-5">
                <v-col v-for="(att, i) in x.attachments" :key="i" class="img__wrap" cols="3">
                    <v-img :src="att.path" aspect-ratio class="grey lighten-2" @click="openInNewTab(att.path)"></v-img>
                    <p class="img__description">اضغط لفتح الصورة</p>
                </v-col>
            </v-row>
            <p class="text-center" v-if="x.attachments == ''">لا توجد مرفقات</p>

            <v-card-title>
                <span class="grey--text ml-2">الزبون :</span>
                {{ x.formUserFullName }}
            </v-card-title>

            <v-card-title>
                <span class="grey--text ml-2">نص الشكوى :</span>
                {{ x.body }}
            </v-card-title>

            <v-card-subtitle class="mt-1">
                <span class="grey--text ml-2">
                    تاريخ
                    <span v-if="x.type == 1" class="error--text">الشكوى</span>
                    <span v-if="x.type == 2" class="green--text">اقتراح</span>
                    :
                </span>
                {{ x.created | formatDate }}
            </v-card-subtitle>

            <v-card-text>
                <span class="grey--text ml-2">الرد :</span>
                {{ x.replay }}
            </v-card-text>

            <v-card-actions class="mt-2">
                <v-btn @click="goBack()" color="primary secondary--text" large>
                    رجوع
                    <v-icon>keyboard_arrow_left</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            x: this.$route.params.obj,
            show: false,
        };
    },

    methods: {
        goBack() {
            window.history.go(-1);
        },

        openInNewTab(src) {
            window.open(src, '_blank').focus();
        },


        downloadImg(responseUrl) {
            const url = window.URL.createObjectURL(new Blob([responseUrl]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = responseUrl.split("_").pop();
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>


<style scoped>
.img__wrap {
    cursor: pointer;
}
.img__description {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(18, 18, 18, 0.72);
    color: #fff;
    visibility: hidden;
    text-align: center;
    transition: opacity 0.2s, visibility 0.2s;
}

.img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
}
</style>
